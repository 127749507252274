import React from 'react';
import axios from 'axios';
import './App.css';

class PONumberForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: ''};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChange(event) {
      this.setState({value: event.target.value});
    }
  
    handleSubmit(event) {
   
      event.preventDefault();
      if (this.state.value !== "") {
      axios({
        method: "POST",
        url: "https://tax_calc.globalts.com:3025/po-id",
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          poId: this.state.value
        }
      }).then(res => {
          if (res.status === 200) {
              alert('success');
              this.shtate = {
                  value: ''
              }
          }
        console.log(res)
      });
    }
      console.log(this.state.value)
    }
  
    render() {
      return (
        <form className="form-container" onSubmit={this.handleSubmit}>
          <input className="order-input" type="text" value={this.state.value} onChange={this.handleChange} placeholder="Purchase Order ID" />
          <button className="btn" type="submit" value="Submit">Submit</button>
        </form>
      );
    }
  }


  export default PONumberForm;