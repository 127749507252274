import logo from './logo.svg';
import './App.css';
import PONumberForm from './PONumberForm';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h5>
         Global Sales Tax Calculator
        </h5>
        <PONumberForm/>
      </header>
    </div>
  );
}

export default App;
